import { closeAlert } from '../Typescript/Shared/AlertHelper';
window.closeAlert = function (element, doNotShowAgain) {
    closeAlert(element, doNotShowAgain);
};
require('jquery-slimscroll');

window.onscroll = function () { scrollFunction() };

function scrollFunction() {
    if (document.getElementById("backToTop")) {
        if (document.body.scrollTop > 320 || document.documentElement.scrollTop > 320) {
            document.getElementById("backToTop").style.display = "block";
        } else {
            document.getElementById("backToTop").style.display = "none";
        }
    }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

/*FUNCTIONS*/
$(document).ready(function () {
    /*GENERAL-FUNCTIONS*/
    //$(".warenkorbImage").attr('src', '../assets/warenkorb_white.svg');
    $.fn.switchChevron = function (cElement, cName01, cName02) {
        //console.log("Element: " + cElement + "; Class Name 01: " + cName01 + "; Class Name 02: " + cName02 + ";");
        if ($(cElement).hasClass("" + cName01 + "")) {
            $(cElement).removeClass("" + cName01 + "").addClass("" + cName02 + "");
        } else {
            $(cElement).removeClass("" + cName02 + "").addClass("" + cName01 + "");
        }
    }
    /*fill search field with used term*/
    var usedSearchTerm = $('input#sucheQuery').val();
    $("#SearchTerm").val(usedSearchTerm);
    showDeleteQueryButton(usedSearchTerm);


    $("body").on("click", ".preventEL,.disabled-Element",
        function (event) {
        event.preventDefault();
    });

    /*MOBILE-NAV*/
    $("#mobileNavCtrl").click(function () {
        $("#mobMenu").css("width", "100%");
        $("header").css("height", "100%");
        $(".mobileModal").css("display", "block");
    });
    $("#mobMenu .closebtn").click(function () {
        $("#mobMenu").css("width", "0");
        $(".mobileModal").css("display", "none");
        $("header").css("height", "initial");
    });

    /**/
    $(".mobile-caps-menu").ready(function () {
        $(".sideBarBody ul").each(function () {
            if ($(this).hasClass("mobSubMenu")) {
                var prevEl = $(this).prev(".wrapper").children("a.mobNavLink").children(".txt");
                //$("<i class='lvlDown'></i>").insertAfter(prevEl);
                //<i class="fa fa-angle-right" aria-hidden="true"></i>
                $("<i class='fa fa-angle-right lvlDown' aria-hidden='true'></i>").insertAfter(prevEl);
            }
        });
        mobileFunctions(this);
    });

    $(".bereichsseiteNav .categorie > p").click(function () {
        var changeCurIcon = $(this).children("i.fa");
        $.fn.switchChevron(changeCurIcon, "fa-angle-down", "fa-angle-up");
        $(this).next("ul.catSub").slideToggle(0);
    });
    //test
    /**/

    /*Category Page Menue*/
    $(".categorySubMenu li").click(function () {

        $(this).toggleClass("selected");
    });
    //categoryPage
    $(".areaSideNav .searchResult-filter-menue").ready(function () {
        $(".categorySubMenu li").each(function () {
            if ($(this).hasClass("selected")) {
                $(this).parent(".categorySubMenu").addClass("active");
                var changeCurIcon = $(this).parent(".categorySubMenu").parent(".categorie").children("p").children("i.fa");
                //$.fn.switchChevron(changeCurIcon, "fa-angle-down", "fa-angle-up");
                if ($(changeCurIcon).hasClass("fa-angle-down")) {
                    $(changeCurIcon).removeClass("fa-angle-down").addClass("fa-angle-up");
                }
            }
        });
    });
    
    /*CHECKOUT-PROCESS-FNS*/
    $(".add-data-section .data-trigger").click(function () {
        var changeCurIcon = $(this).children("i.fa");
        $.fn.switchChevron(changeCurIcon, "fa-angle-down", "fa-angle-up");
        $(this).next(".addtional-data").slideToggle();
    });

    $(".m-close").click(function (event) {
        event.preventDefault();
        $("body").removeClass("modal-open");
        $("#modal-bg-layover").hide();
        $(this).parents(".basket-modal").hide();
        $(this).parents(".basket-modal").removeClass("in");
    });

    $(".data-acc-trigger").click(function (event) {
        event.preventDefault();
        if ($(window).width() < 992) {
            var changeCurIcon = $(this).find("i.fa");
            $.fn.switchChevron(changeCurIcon, "fa-angle-down", "fa-angle-up");
            $(this).children(".heading").toggleClass("active");
            $(this).next(".data-acc-section").slideToggle();
        }
    });
    /*TOOLTIP*/
    $("body").on("click", ".chb-tooltip", function (event) {
        event.preventDefault();
        var windowWidth = $(window).width();
        var ttDataTarget = $(this).attr("data-target");
        if (windowWidth < 801 && ttDataTarget) {
            $("body").addClass("modal-open");
            $("#modal-bg-layover").show();
            $(ttDataTarget).show();
        }
    });
    $("body").on("click", ".my-account-menu", function () {
        event.preventDefault();
        var windowWidth = $(window).width();
        if (windowWidth > 801) {
            if ($(this).hasClass('active')) {
                $('.my-account-menu').removeClass('active');
                $('.my-account-menu-bubble').removeClass('active');
            } else {
                $('.my-account-menu').addClass('active');
                $('.my-account-menu-bubble').addClass('active');
            }
        }
    });
    /**/
    $(".hideContent").each(function () {
        $(this).hide(0);
    });

    $("#edit-existing-account").click(function (event) {
        event.preventDefault();
        $("#mm-content-01").find(".link").hide();
        $("#mm-content-01").next(".min-max-content").removeClass("hideContent").slideDown();
        $("#mm-content-02").next(".min-max-content").removeClass("hideContent").slideUp();
        $("#mm-content-03").next(".min-max-content").removeClass("hideContent").slideUp();
    });

    $("body").on("click", ".acc-trigger", function () {
        var currentEl = $(this).next(".accordion-content");
        $(this).parents(".accordion-wrapper").find("*.accordion-content").not(currentEl).slideUp(300);
        $(currentEl).slideDown(300);
    });
    /**/
    $("body").on("click", ".blend-all-collapsibles", (function () {
        $(this).find(".content").each(function () {
            $(this).toggleClass("el-hidden");
        });
        //
        $(this).parents(".mm-wrapper").find(".wr-collapsible").each(function () {
            $(this).slideToggle("fast");
        });
    }));
    /**/
    $(".footerSmallNavi p.footerheading").click(function () {
        var changeCurIcon = $(this).children("i.fa");
        $.fn.switchChevron(changeCurIcon, "fa-angle-down", "fa-angle-up");
        $(this).next("ul").slideToggle(0);
        //console.log($(this).next());
    });
    
    /*PRODUCT-DETAILS*/
    $(document).ready(function () {
        // Configure/customize these variables.
        var showChar = 50;  // How many characters are shown by default
        var ellipsestext = "...";
        var moretext = "<i class='fa fa-angle-down' aria-hidden='true'></i> mehr ";
        var lesstext = "<i class='fa fa-angle-up' aria-hidden='true'></i> weniger ";


        $('.more').each(function () {
            var content = $(this).html();

            if (content.length > showChar) {

                var c = content.substr(0, showChar);
                var h = content.substr(showChar, content.length - showChar);

                var html = c + '<span class="moreellipses">' + ellipsestext + '&nbsp;</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink">' + moretext + '</a></span>';

                $(this).html(html);
            }

        });

        $(".morelink").click(function () {
            if ($(this).hasClass("less")) {
                $(this).removeClass("less");
                $(this).html(moretext);
            } else {
                $(this).addClass("less");
                $(this).html(lesstext);
            }
            $(this).parent().prev().toggle();
            $(this).prev().toggle();
            return false;
        });


    });
    //---MORE-LESS-VERSION-2
    // Hide the extra content initially, using JS so that if JS is disabled, no problemo:
    $('.read-more-content').addClass('hide')
    $('.read-more-show, .read-more-hide').removeClass('hide')

    // Set up the toggle effect:
    $('.read-more-show').on('click', function (e) {
        $(this).next('.read-more-content').removeClass('hide');
        $(this).addClass('hide');
        e.preventDefault();
    });

    // Changes contributed by @diego-rzg
    $('.read-more-hide').on('click', function (e) {
        var p = $(this).parent('.read-more-content');
        p.addClass('hide');
        p.prev('.read-more-show').removeClass('hide'); // Hide only the preceding "Read More"
        e.preventDefault();
    });


    /**/
    /*ACCORDEON*/
    $('.expand').click(function () {
        var changeCurIcon = $(this).children("i.fa");
        $.fn.switchChevron(changeCurIcon, "fa-angle-right", "fa-angle-down");
        $(this).next(".expandContent").slideToggle(0);
    });
    $(function () {
        $(".accordeonExp").on("click", function () {
            $(this).next().slideToggle(0);
            //$expand = $(this).find(">:first-child");
            $expand = $(this).children(".right-arrow").children("i.fa");
            $.fn.switchChevron($expand, "fa-angle-down", "fa-angle-up");
            //if ($expand.text() == "<i class='fa fa-angle-down'></i>") {
            //    $expand.text("<i class='fa fa-angle-up'></i>");
            //} else {
            //    $expand.text("<i class='fa fa-angle-down'></i>");
            //}
            if ($expand) {

            } else {

            }
        });
    });
    /**/
    $(".productDetailView .options .switcher").click(function () {
        //var currentElement = $(this);
        $(".switcher").each(function () {
            var iconEl = $(this).children("div").children("h3").children(".at").children("i.fa");
            if ($(iconEl).hasClass("fa-angle-up")) {
                $(iconEl).removeClass("fa-angle-up").addClass("fa-angle-down");
                $(this).children(".item-content").slideToggle(0);
                $(this).toggleClass("active");
            }

        });

        var changeCurIcon = $(this).find(".at").children("i.fa");
        if (changeCurIcon.length === 0) {
            return;
        }
        $.fn.switchChevron(changeCurIcon, "fa-angle-down", "fa-angle-up");
        $(this).children(".item-content").slideToggle(0);
        $(this).toggleClass("active");

    });
    $(".tab-switcher-mobile").click(function () {
        var openedTab = $(this);
        var currentID = $(this).next(".tab-pane").attr("id");
        var changeCurIcon = $(this).children("a").children("i.fa");
        var allTabIcons = $(".tab-switcher-mobile").children("a").children("i.fa");
        //$.fn.switchChevron(changeCurIcon, "fa-angle-down", "fa-angle-up");
        var tabPane = $(this).next(".tab-pane");
        //console.log("currentID: " + currentID);
        if ($(tabPane).hasClass("active")) {
            //console.log("tab-pane has class 'active'.");

        } else {
            $(allTabIcons).each(function () {
                //$.fn.switchChevron($(this), , );
                $(this).removeClass("fa-angle-up").addClass("fa-angle-down");
            });
            $(".tab-switcher-mobile").each(function () {
                $(this).removeClass("open");
            });
            $.fn.switchChevron(changeCurIcon, "fa-angle-down", "fa-angle-up");
            //$(openedTab).addClass("active");
        }
        $(this).addClass("open");
        var nav = $("#" + currentID);
    });

    /*POP-OVERS*/

    //$("#popover-filter-1")

    $("a[data-role='popover-t1']").click(function (event) {
        event.preventDefault();
        var getID = $(this).attr("data-target");
        var windowWidth = $(window).width();
        var windowHeight = $(window).height();
        if (windowWidth < 1024) {
            $("#" + getID).show(0);
            $("body").addClass("noScroll");

        }
    });
    $(".popover-close").click(function () {
        $(this).parent().parent().parent(".popover-modal").hide(0);
        $("body").removeClass("noScroll");
    });

    //Schneidet den Inhalt der Autorenfelder bei dem Produktkarussell nach dem letzten vom Platz her passenden Wort ab und erg�nz drei Punkte 
    const allKarussellAuthorElements = $("[id^=produktkarussell] .author a");
    Array.prototype.forEach.call(allKarussellAuthorElements,
        (authorElement) => { // Loop through each container
            var span = authorElement.getElementsByClassName("authorSpan")[0];
            var a = authorElement.clientHeight;
            if (span) {
                while (span.offsetHeight > a) { // Check if the paragraph's height is taller than the container's height. If it is:
                    span.textContent =
                        span.textContent.replace(/\W*\s(\S)*$/, '...'); // add an ellipsis at the last shown space
                }
            }

        });

    //Schneidet den Inhalt der Titel bei dem Produktkarussell nach dem letzten vom Platz her passenden Wort ab und erg�nz drei Punkte
    const allKarussellTitleElements = $("[id^=produktkarussell] .title a");
    Array.prototype.forEach.call(allKarussellTitleElements,
        (titleElement) => { // Loop through each container
            var span = titleElement.getElementsByClassName("titleSpan")[0];
            var a = titleElement.clientHeight;
            if (span) {
                while (span.offsetHeight > a) { // Check if the paragraph's height is taller than the container's height. If it is:
                    span.textContent =
                        span.textContent.replace(/\W*\s(\S)*$/, '...'); // add an ellipsis at the last shown space
                }
            }

        });

    cutTextWholeWords();
    /**/
    /**/
    $(function () {
        naviSlimScroll();
        $(".katNaviScroll").each(function () {
            if ($(this).hasClass("active")) {
                //$(this).parent(".slimScrollDiv").css("display","block");
                //console.log($(this).parent(".slimScrollDiv"));
                $(this).parent(".slimScrollDiv").css("display", "block");
                //$(this).parent().addClass("test");
            }
        });
    });
});


function showDeleteQueryButton(query) {
    if (query && query !== '') {
        $('.eraseSearchTerm').removeClass('hide');
    }
}
export function setDummyImage(elem) {
    var placeholderUrl = location.protocol + "//" + location.hostname + ":" + location.port + '/images/NoBook_tranparent.png';
    if (elem.src !== placeholderUrl) {
        elem.src = placeholderUrl;
    } else {
        elem.style.display = 'none';
        elem.onerror = null;
    }
}

export function DelocaleString(x, sep, grp, dec) {
    //add decimals
    var y = x.toFixed(dec);
    //strip decimals
    var x_integer = y.split('.')[0];
    var x_fraction = y.split('.')[1];
    var x_fractionString = "";
    if (dec > 0) {
        x_fractionString = ',' + x_fraction;
    }
    var sx = ('' + x_integer).split('.'),
        s = '',
        i, j;
    sep || (sep = ' '); // default seperator
    grp || grp === 0 || (grp = 3); // default grouping
    i = sx[0].length;
    while (i > grp) {
        j = i - grp;
        s = sep + sx[0].slice(j, i) + s;
        i = j;
    }
    s = sx[0].slice(0, i) + s;
    sx[0] = s;
    return sx.join('.') + x_fractionString;
}

export function mobileFunctions(doc) {

    $(".sideBarBody ul").each(function (i, el) {
        $(this).attr("level", $(this).parents("ul").length);
    });

    $(doc).find("ul.mobSubMenu").prepend("<p class='backLink'> <i class='fa fa-angle-left' aria-hidden='true'></i><span class='txt'>zur&uuml;ck</span></p><p class='parentNode'></p>");
    $(".backLink").each(function () {
        var parentMenuText = $(this).parent("ul").parent("li").children(".wrapper").children("a.mobNavLink").text();
        $(this).next("p.parentNode").text(parentMenuText);
    });

    $("a.mobNavLink").click(function (event) {
        var currentParent = $(event.target).closest(".sideBarBody");
        /*in case of popover find poover header and hide it*/
        var findPopupHeader = $(this).closest(".mobMenu").parent(".sideBarBody").parents("div.popover-body ").prev(".popover-header");
        $(findPopupHeader).hide(0);
        /**/
        var getWrapper = $(this).parent(".wrapper");
        if ($(getWrapper).next("ul").length > 0) {
            event.preventDefault();

            $(".mobile-caps-menu ul").each(function () {
                $(this).removeClass("currentM");
            });
            var subUl = $(getWrapper).next("ul.mobSubMenu");
            var getChildLevel = $(getWrapper).next("ul.mobSubMenu").attr("level");
            var getParentLevel = getChildLevel - 1;
            subUl.addClass("currentM");
            if (subUl && !subUl.hasClass("open")) {
                subUl.addClass("open");
                currentParent.find("ul[level='" + getParentLevel + "']").children("li").children(".wrapper").hide(0);
                currentParent.find("ul[level='" + getParentLevel + "']").children(".backLink").hide(0);
                currentParent.find("ul[level='" + getParentLevel + "']").children(".parentNode").hide(0);
                currentParent.find("ul[level='" + getChildLevel + "']").children(".backLink").show(0);

                subUl.animate({
                    left: 0,
                    opacity: 1.0
                }, 0, "linear");
            }
        }
        //$(".mobile-caps-menu").height($("ul.currentM").height());
    });

    $(".backLink").click(function () {
        $(".mobile-caps-menu ul").each(function () {
            $(this).removeClass("currentM");
        });

        var getChildLevel = $(this).parent("ul").attr("level");
        var getParentLevel = getChildLevel - 1;
        var grandParentLevel = getParentLevel - 1;
        $(this).parent("ul").parent("li").parent("ul").addClass("currentM");
        $(this).parent("ul.mobSubMenu").removeClass("open").animate({ left: "100%", opacity: 0.0 }, 0, "linear");

        $(this).hide();
        $("ul[level='" + getParentLevel + "']").children(".backLink").show(0);
        $("ul[level='" + getParentLevel + "']").children(".parentNode").show(0);
        $("ul[level='" + getParentLevel + "']").children("li").children(".wrapper").show(0);
        /*in case of popover find popover header and display it when link's parent level is 0*/
        if (getParentLevel == 0) {
            var findPopupHeader = $(this).closest(".mobMenu").parent(".sideBarBody").parents("div.popover-body ").prev(".popover-header");
            $(findPopupHeader).show(0);
        }
    });
    $(function () {
        naviSlimScroll();
        $(".slimScrollDiv").css("display", "none");
        $(".katNaviScroll").each(function () {
            if ($(this).hasClass("active")) {
                //$(this).parent(".slimScrollDiv").css("display","block");
                //console.log($(this).parent(".slimScrollDiv"));
                $(this).parent(".slimScrollDiv").css("display", "block");
                //$(this).parent().addClass("test");
            }
        });
    });

    $(".searchResult-filter-menue .categorie > p").click(function () {
        toggleCatMenu($(this.parentElement));
    });

    cutTextWholeWords();
}

function toggleCatMenu(doc) {
    var changeCurIcon = doc.children("p").children("i.fa");
    $.fn.switchChevron(changeCurIcon, "fa-angle-down", "fa-angle-up");
    if (doc.children("p").next("div.slimScrollDiv").length > 0) {
        doc.children("div.slimScrollDiv").slideToggle(0);
    } else {
        doc.find(".categorySubMenu").slideToggle(0);
    }
}

function naviSlimScroll() {
    $(".katNaviScroll").slimScroll({
        color: '#8f7747',
        size: '8px',
        height: '300px',
        alwaysVisible: true,
        railVisible: true,
        railColor: '#f1efee',
        railOpacity: 1.0
    });
}

$(".submitGewinnspiel").click(function (event) {

    if (grecaptcha == undefined) {
        $('#gewinnspiel_Recaptcha_Validation').removeClass('hide');
        return false;
    }
    try {
        var recaptchaResponse = grecaptcha.getResponse();
    } catch {
        $('#gewinnspiel_Recaptcha_Validation').removeClass('hide');
        return false;
    }
    if (recaptchaResponse.length == 0) {
        $('#gewinnspiel_Recaptcha_Validation').removeClass('hide');
        return false;
    }

    var id = $(this).data("id");
    var addressform = $(this).data("addressform");
    var gewinnspielForm = $("#gewinnspielForm_" + id);
    //Blendet die ValidationMessages aus
    gewinnspielForm.find(".validationMessage").addClass("hide");
    var antwort = gewinnspielForm.find("#gewinnspiel_Answer").val();
    var salutation = gewinnspielForm.find("#gewinnspiel_Salutation").val();
    var title = gewinnspielForm.find("#gewinnspiel_Title").val();
    var vorname = gewinnspielForm.find("#gewinnspiel_Vorname").val();
    var nachname = gewinnspielForm.find("#gewinnspiel_Nachname").val();
    var email = gewinnspielForm.find("#gewinnspiel_Email").val();
    var street = gewinnspielForm.find("#gewinnspiel_Street").val();
    var city = gewinnspielForm.find("#gewinnspiel_City").val();
    var postalCode = gewinnspielForm.find("#gewinnspiel_PostalCode").val();
    var dataUsageAccepted = gewinnspielForm.find("#gewinnspiel_DataUsageAccepted").prop('checked');
    var newsletterAccepted = gewinnspielForm.find("#gewinnspiel_NewsletterAccepted").prop('checked');
    var gewinnspielEntry = {};
    gewinnspielEntry["Addressform"] = addressform;
    gewinnspielEntry["GewinnspielId"] = id;
    gewinnspielEntry["Antwort"] = antwort;
    gewinnspielEntry["Salutation"] = salutation;
    gewinnspielEntry["Title"] = title;
    gewinnspielEntry["Vorname"] = vorname;
    gewinnspielEntry["Nachname"] = nachname;
    gewinnspielEntry["Email"] = email;
    gewinnspielEntry["Street"] = street;
    gewinnspielEntry["City"] = city;
    gewinnspielEntry["PostalCode"] = postalCode;
    gewinnspielEntry["DataUsageAccepted"] = dataUsageAccepted;
    gewinnspielEntry["NewsletterAccepted"] = newsletterAccepted;
    gewinnspielEntry["RecaptchaResponse"] = recaptchaResponse;

    $.post("/Umbraco/Api/Gewinnspiel/SendGewinnspielEntry", gewinnspielEntry,
        function (data, status) {
            //success
            $("#gewinnspielForm_" + id).addClass("hide");
            $("#confirmation_" + id).removeClass("hide");
        }).fail(function (data) {
            setModelstateErrorsGewinnspiel(data.responseJSON.ModelState, gewinnspielForm);
        });
});
/**
 * Schreibt die Meldungen aus dem Modelstate in die daf�r vorgesehenen Spans und zeigt diese an
 * @param {any} modelstate
 * @param {any} gewinnspielForm
 */
function setModelstateErrorsGewinnspiel(modelstate, gewinnspielForm) {
    if (modelstate !== undefined) {
        if (modelstate["entryJson.Antwort"] !== undefined) {
            gewinnspielForm.find("#gewinnspiel_Answer_Validation").html(modelstate["entryJson.Antwort"][0]).removeClass("hide");
        }
        if (modelstate["entryJson.DataUsageAccepted"] !== undefined) {
            gewinnspielForm.find("#gewinnspiel_DataUsageAccepted_Validation").html(modelstate["entryJson.DataUsageAccepted"][0]).removeClass("hide");
        }
        if (modelstate["entryJson.Email"] !== undefined) {
            gewinnspielForm.find("#gewinnspiel_Email_Validation").html(modelstate["entryJson.Email"][0]).removeClass("hide");
        }
        if (modelstate["entryJson.Nachname"] !== undefined) {
            gewinnspielForm.find("#gewinnspiel_Nachname_Validation").html(modelstate["entryJson.Nachname"][0]).removeClass("hide");
        }
        if (modelstate["entryJson.Vorname"] !== undefined) {
            gewinnspielForm.find("#gewinnspiel_Vorname_Validation").html(modelstate["entryJson.Vorname"][0]).removeClass("hide");
        }
        if (modelstate["entryJson.City"] !== undefined) {
            gewinnspielForm.find("#gewinnspiel_City_Validation").html(modelstate["entryJson.City"][0]).removeClass("hide");
        }
        if (modelstate["entryJson.PostalCode"] !== undefined) {
            gewinnspielForm.find("#gewinnspiel_PostalCode_Validation").html(modelstate["entryJson.PostalCode"][0]).removeClass("hide");
        }
        if (modelstate["entryJson.Street"] !== undefined) {
            gewinnspielForm.find("#gewinnspiel_Street_Validation").html(modelstate["entryJson.Street"][0]).removeClass("hide");
        }
        if (modelstate["entryJson.Salutation"] !== undefined) {
            gewinnspielForm.find("#gewinnspiel_Salutation_Validation").html(modelstate["entryJson.Salutation"][0]).removeClass("hide");
        }
    }
}
export function cutTextWholeWords() {
    //Schneidet den Inhalt der Titel,publisher und descriptions bei der Trefferliste nach dem letzten vom Platz her passenden Wort ab und erg�nz drei Punkte
    const allElementsToCut = $(".FFTrefferlistenProdukt .publisher, .FFTrefferlistenProdukt .title, .FFTrefferlistenProdukt .description");
    Array.prototype.forEach.call(allElementsToCut,
        (cutElement) => { // Loop through each container
            var span = cutElement.getElementsByClassName("cutSpan")[0];
            if (!span) {
                return;
            }
            var p = cutElement.clientHeight;
            while (span.offsetHeight > p) {
                // Check if the paragraph's height is taller than the container's height. If it is:
                span.textContent =
                    span.textContent.replace(/\W*\s(\S)*$/, '...'); // add an ellipsis at the last shown space
            }
        });
}