import { AutosuggestModel } from './Models/ffAutosuggestModel';
import { setDummyImage } from '../../scripts/functions.js';
import { availabilityStatusFilter } from './Models/ffConstants';
import { factfinderSuggestTracking } from './Tracking/factFinderTracking';

$(() => {
    /**
     * Ab 3 Zeichen (ohne Leerzeichen) soll dem Kunden ein Autosuggest-Vorschlag gemacht werden
     */
    $('.mainSearchInput').on('input', function() {
        var query = ($(this).val() + '').trim();
        if (query !== '') {
            $(this)
                .closest('.inputEls')
                .find('.eraseSearchTerm')
                .removeClass('hide');
        }
        if (query == '') {
            $(this)
                .closest('.inputEls')
                .find('.eraseSearchTerm')
                .addClass('hide');
        }
        if (query !== '' && query.length > 2) {
            getAutoSuggestResults($(this).val());
        } else if (query == '' || query.length < 3) {
            $('.autoSuggest').removeClass('autoSuggest-container');
        }

        var changeCurIcon = $('.showMoreSubjects').children('i.fa');
        if ($(changeCurIcon).hasClass('fa-angle-up')) {
            $(changeCurIcon)
                .removeClass('fa-angle-up')
                .addClass('fa-angle-down');
            $('.moreSubjects').hide(0);
        }
    });

    $('.autoSuggest').on('click', '.suggest-tracking', function() {
        const area = $(this).data('area') as string;

        if (area) {
            factfinderSuggestTracking(area);
        }
    });

    /**
     * Fragt die Daten vom Controller ab
     * @param query
     */
    function getAutoSuggestResults(query) {
        $.get('/umbraco/api/FfAutosuggest/GetSuggest?query=' + query, data => {
            var model = data as AutosuggestModel;
            clearSuggests();
            if (model != null && model.suggestions != null && model.suggestions.length > 0) {
                var displaysElements: boolean = fillSuggestContainer(model, query);
                if (displaysElements) {
                    $('.autoSuggest').addClass('autoSuggest-container');
                } else {
                    $('.autoSuggest').removeClass('autoSuggest-container');
                }
            } else {
                $('.autoSuggest').removeClass('autoSuggest-container');
            }
        });
    }

    /**
     * Füllt das Autosuggest-Overlay aus dem Model
     * @param model
     * @param query
     */
    function fillSuggestContainer(model: AutosuggestModel, query): boolean {
        let displaysElements = false;
        var hiddenSuggestElements = $('#hiddenSuggestElements');
        let productResultsCount = 0;
        const originalQuery = query;
        query = capitalize(query);
        for (let suggestion of model.suggestions) {
            if (suggestion.type === 'searchTerm') {
                //Kopiert das Suchvorschlag-Element
                var suchvorschlag = hiddenSuggestElements.find('.Suchvorschlag').clone();
                //Bringt die Usersuche un das selbe Format wie die Antwort von FF
                var searchquery = umlauteAnpassen(query).toUpperCase();
                //Füllt den suggest, hängt es dem passenden Container an und zeigt den Container an.
                if (suggestion.name != null) {
                    var markedSuggest = markSuggest(suggestion.name, searchquery);
                    suchvorschlag.find('a').attr('href', `/suche?query=${suggestion.name}&userInput=${query}`);
                    var span = hiddenSuggestElements.find('#SuggestElementsToClone span').clone();
                    span.html(markedSuggest);
                    suchvorschlag.find('a').append(span);
                    $('.Suchvorschlaege .suggestItems').append(suchvorschlag);
                    $('.Suchvorschlaege').show();
                    displaysElements = true;
                }
            } else if (suggestion.type === 'category') {
                var category = hiddenSuggestElements.find('.Kategorievorschlag').clone();
                if (suggestion.name != null) {
                    var formattedQuery = umlauteAnpassen(query).toUpperCase();
                    var markedCategory = markSuggest(suggestion.name, formattedQuery);
                    category.find('a').attr('href', suggestion.attributes.deeplink);
                    var spanCategory = hiddenSuggestElements.find('#SuggestElementsToClone span').clone();
                    spanCategory.html(markedCategory);
                    category.find('a').append(spanCategory);
                    $('.Kategorievorschlaege .suggestItems').append(category);
                    $('.Kategorievorschlaege').show();
                    displaysElements = true;
                }
            } else if (suggestion.type === 'Service') {
                //Kopiert das Servicevorschlag-Element
                var servicethema = hiddenSuggestElements.find('.Servicethema').clone();
                //Füllt den suggest, hängt es dem passenden Container an und zeigt den Container an.
                if (suggestion.name != null) {
                    var markedSuggestThema = markSuggest(suggestion.name, query);
                    var spanThema = hiddenSuggestElements.find('#SuggestElementsToClone span').clone();
                    servicethema.find('a').attr('href', '/suche?query=' + suggestion.name);
                    spanThema.html(markedSuggestThema);
                    servicethema.find('a').append(spanThema);
                    $('.Servicethemen .suggestItems').append(servicethema);
                    $('.Servicethemen').show();
                    displaysElements = true;
                }
            } else if (suggestion.type === 'productName') {
                if (
                    availabilityStatusFilter.includes(suggestion.attributes.availabilityStatusId) &&
                    productResultsCount < 3
                ) {
                    productResultsCount++;
                    //Kopiert das Titelvorschlag-Element
                    var titelvorschlag = hiddenSuggestElements.find('.Titelvorschlag').clone();
                    //Füllt den suggest, hängt es dem passenden Container an und zeigt den Container an.
                    titelvorschlag.find('.title').html(suggestion.name != null ? suggestion.name : '');

                    if (suggestion.image != null) {
                        var widthParam = suggestion.image.indexOf('&width=56');
                        if (widthParam > 0) {
                            suggestion.image = suggestion.image.replace('&width=56', '');
                        }
                        suggestion.image = suggestion.image + '&height=80';
                    }
                    titelvorschlag
                        .find('img')
                        .attr('src', suggestion.image != null ? suggestion.image : '')
                        .on('error', function() {
                            setDummyImage(this);
                        });
                    if (suggestion.attributes != null) {
                        titelvorschlag.attr(
                            'href',
                            suggestion.attributes.deeplink
                                ? suggestion.attributes.deeplink
                                : `/product/${suggestion.attributes.id}`
                        );
                        titelvorschlag
                            .find('.author')
                            .html(
                                suggestion.attributes.urheberzeile != null
                                    ? markSuggest(suggestion.attributes.urheberzeile, query)
                                    : ''
                            );
                        titelvorschlag
                            .find('.volume')
                            .html(
                                suggestion.attributes.auflage != null
                                    ? markSuggest(suggestion.attributes.auflage, query)
                                    : ''
                            );
                    }
                    $('.Titelvorschlaege .suggestItems').append(titelvorschlag);
                    $('.Titelvorschlaege').show();
                    displaysElements = true;
                }
            }
        }

        if (productResultsCount === 0) {
            $('.allSearchResultsLink').hide();
        } else {
            $('.allSearchResultsLink').show();
            $('.allSearchResultsLink').attr('href', `/suche?query=${originalQuery}`);
        }

        return displaysElements;
    }

    /**
     * markiert die gesuchten wörter im suchvorschlag
     * @param suggestion
     * @param searchquery
     */
    function markSuggest(suggestion, searchquery) {
        var re = new RegExp(searchquery.replace(/ /g, '|'), 'gi');

        return suggestion.replace(re, function(matched) {
            return '<span class="red">' + matched + '</span>';
        });
    }

    /**
     * Löscht die Vorschläge
     */
    function clearSuggests() {
        $('.suggestItems').html('');
        $('.Suchvorschlaege').hide();
        $('.Servicethemen').hide();
        $('.Kategorievorschlaege').hide();
        $('.Titelvorschlaege').hide();
    }

    /**
     * Passt die Umlaute in einem String an. zB ä => ae
     * @param query
     */
    function umlauteAnpassen(query) {
        return query
            .replace(/\u00e4/g, 'ae')
            .replace(/\u00c4/g, 'Ae')
            .replace(/\u00fc/g, 'ue')
            .replace(/\u00dc/g, 'Ue')
            .replace(/\u00f6/g, 'oe')
            .replace(/\u00d6/g, 'Oe');
    }

    function capitalize(s) {
        return s && s[0].toUpperCase() + s.slice(1);
    }
});
/**
 * Lässt das Suggestfenster verschwinden, wenn man danaben klickt
 */
$(document).mouseup(e => {
    var container_01 = $('.autoSuggest');

    // if the target of the click isn't the container nor a descendant of the container
    if (e.target instanceof Element && container_01.has(e.target).length === 0 && !container_01.is(e.target)) {
        container_01.removeClass('autoSuggest-container');
    }
    CloseDropDownWindows(e);
});

function CloseDropDownWindows(e) {
    var container_02 = $('.showMoreSubjects');
    if (!container_02.is(e.target) && container_02.has(e.target).length === 0) {
        //container_02.hide();
        var changeCurIcon = $(container_02).children('i.fa');
        if ($(changeCurIcon).hasClass('fa-angle-up')) {
            $(changeCurIcon)
                .removeClass('fa-angle-up')
                .addClass('fa-angle-down');
            $('.moreSubjects').hide(0);
            $('.moreSubjectsTestContainer').hide(0);
        }
    }
    const container_03 = $('.basket-menu-bubble');
    if (!container_03.is(e.target) && container_03.has(e.target).length === 0) {
        container_03.hide();
    }
}
