let lastScrollTop = 0;
const scrollDelta = 150;
const navbarHeight = $('header').outerHeight();

function showStickyNav() {
    const scrollTop = $(this).scrollTop();

    if (scrollTop === 0) {
        $('header').removeClass('sticky');
        $('main').css('padding-top', 0);
        lastScrollTop = scrollTop;
        return;
    }

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - scrollTop) <= scrollDelta) {
        return;
    }

    // If they scrolled down and are past the navbar, add class sticky.
    if (scrollTop > lastScrollTop && scrollTop > navbarHeight) {
        // Scroll Down
        $('header').removeClass('sticky');
        $('main').css('padding-top', 0);
    } else {
        // Scroll Up
        if (scrollTop + $(window).height() < $(document).height() && scrollTop < lastScrollTop) {
            $('header').addClass('sticky');
            $('main').css('padding-top', navbarHeight);
        }
    }

    lastScrollTop = scrollTop;
}

$(window).on('scroll', function() {
    showStickyNav();
});
